import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/_ENm6yBz7Ls">
    <SEO title="Old vs. New - Hebrews" />
  </Layout>
)

export default SermonPost
